// -------- import official less file -----------
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import './styles/variables.less';

// -------- override less var -----------
@primary-color: #3099cc;

.ant-layout {
    background: #f7f9fa;
}

:root {
    --ngx-json-key: @text-primary;
}

.nowrap {
    white-space: nowrap;
}

nz-page-header.ant-page-header {
    display: flex;
    align-items: center;
    height: 75px;
    padding: 0;

    .ant-page-header-heading-extra {
        position: absolute;
        top: 21px;
        right: 24px;
    }
}

.table-actions-container {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    padding-right: 1.25rem;
    height: 100%;
    width: 32.5rem;
    background: linear-gradient(
        270deg,
        #ffffff 50%,
        rgba(255, 255, 255, 0) 100%
    );
    transition: all 0.4s ease-in-out;
    .action-icon {
        color: @dark-gray;
        font-size: 1.125rem;
        margin-left: 1.175rem;
        transition: opacity 0.6s ease-in-out;
        &:hover {
            color: @blue-color;
            cursor: pointer;
            &.delete {
                color: @text-red;
            }
        }
    }
}

/* ANT TABLE */

.centered-cell {
    display: flex;
    align-items: center;
    width: 100%;
}

.ant-form-item-explain-connected {
    height: auto;
}

.ant-table {
    .ant-table-tbody > tr:hover:not(.ant-table-placeholder) > td {
        background: @background-gray;
    }
    .ant-table-cell-fix-right, .ant-table-cell-fix-left {
        background: white !important;
    }
    .ant-table-tbody {
        .actions__cell {
            &:hover,
            &.active {
                .table-actions-container {
                    display: flex;
                }
            }
        }
    }
    .ant-table-thead > tr > th {
        padding: 0.5rem 0.25rem !important;
        &:before {
            display: none;
        }
    }
    nz-table-sorters {
        flex-direction: row-reverse;
        justify-content: flex-end;
        .ant-table-column-title {
            transition: all 0.2s ease-in-out;
        }
        &:hover {
            .ant-table-column-title {
                color: @text-primary;
            }
        }
    }
    .ant-table-thead
        > tr
        > th
        .ant-table-column-sorters
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-up,
    .ant-table-thead
        > tr
        > th
        .ant-table-column-sorters
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-down {
        display: none;
    }
    .ant-table-thead
        > tr
        > th
        .ant-table-column-sorters
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-up.active,
    .ant-table-thead
        > tr
        > th
        .ant-table-column-sorters
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-down.active {
        display: block;
        font-size: 1.3125rem;
        margin-top: -0.25rem;
    }
    .ant-table-thead > tr > th {
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        color: @text-tertiary;
        background: none;
        border-bottom: 1px solid @outline-gray;
        position: sticky;
        top: 0;
        background: @white-color;
        z-index: 2;
        padding: 0.5rem 0.5rem !important;
    }
    tr.not-sticky > th {
        position: relative;
        top: auto;
    }
    .ant-table-thead > tr > th:hover {
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        color: @text-tertiary;
        background: none;
        border-bottom: 1px solid @outline-gray;
        background: @white-color;
        z-index: 2;
    }
    .ant-table-middle
        > .ant-table-content
        > .ant-table-body
        > table
        > .ant-table-tbody
        > tr
        > td {
        padding: 1rem 0;
    }
    .ant-table-tbody > tr > td {
        color: @text-primary;
        font-size: 1rem;
        letter-spacing: 0.01em;
        font-family: 'Roboto', sans-serif;
        border-bottom: 1px solid @outline-gray;

        &.actions__cell {
            text-align: right;
            position: relative;
            padding-right: 0 !important;
        }
    }
    .ant-table-thead
        > tr
        > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
        background: none;
    }
    .ant-table-column-sorter {
        margin-left: 0 !important;
    }
    .ant-table-thead
        > tr
        > th
        .ant-table-header-column
        .ant-table-column-sorters {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
}



.table-overlay__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

/**
 ANT BUTTTONS
**/

.ant-btn,
.ant-btn-icon-only,
.ant-btn-icon-only.ant-btn-sm {
    border-radius: 0.0625rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-family: 'Poppins', sans-serif;
    padding: 0.6875rem 1rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    .button-icon {
        font-size: 0.625rem;
    }
}

.ant-btn-icon-only.ant-btn-sm {
    width: 2.75rem;
    display: flex;
    align-items: center;
}

.ant-btn-primary {
    text-shadow: none;
    box-shadow: none;
    &:disabled,
    &:hover:disabled {
        color: white;
        opacity: 0.4;
        background: @primary-color;
        border: 1px solid transparent;
    }
}

.ant-btn-default {
    border: 1px solid @outline-gray;
}

/**
  ANT NUMBER INPUT
**/

.ant-input-number {
    padding: 0.125rem 0 !important;
    margin-left: -0.125rem;
}

/**
    ANT INPUT
**/

.ant-input-number {
    border-color: @outline-gray;
}

.ant-input.disabled {
    background-color: #f5f5f5;
}

.ant-input-group-lg .ant-input {
    height: 2.75rem;
}

.ant-input-affix-wrapper .ant-input-prefix i {
    font-size: 1rem;
}

.ant-input-affix-wrapper .ant-input-prefix {
    left: 0.6875rem;
}

.ant-select-selection-search-input {
    margin-top: 0.25rem !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-picker-range:hover,
.ant-picker-range:focus,
.ant-picker-focused {
    box-shadow: none !important;
    border-color: @text-primary !important;
    border: 1px solid @text-primary;
}

.has-error .ant-input {
    &:focus,
    &:hover {
        border: 1px solid @text-red !important;
    }
}

.input-length-tracker {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: @blue-color;
}

.ant-input {
    border-radius: 0.125rem !important;
    border-color: @outline-gray;
    ::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        line-height: 1.375rem;
        letter-spacing: 0.01em;
        color: @text-tertiary;
    }
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 0.5rem;
}

&.ant-select-disabled {
    .ant-select-selection-selected-value {
        background-color: @disabled-background-color;
        color: rgba(0, 0, 0, 0.25);
    }
}

.ant-input[disabled] {
    color: @text-primary;
    background-color: @disabled-background-color;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

/**
    ANT FORM CONTROLS
**/
&.disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
}

.disabled {
    .ant-upload {
        pointer-events: none;
    }
    .attach-file-button {
        opacity: 0.75;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.02);
    }
    .ant-radio-wrapper {
        opacity: 0.85;
        pointer-events: none;
        .ant-radio-inner {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    input {
        color: rgba(0, 0, 0, 0.25);
        background-color: @disabled-background-color;
        cursor: not-allowed;
    }
    .color-picker-container {
        input {
            pointer-events: none;
        }
    }
    nz-select {
        pointer-events: none;
    }
    .ant-select-selection,
    .ant-select-selection-selected-value,
    .ant-select-selection__rendered {
        color: rgba(0, 0, 0, 0.25);
        background-color: @disabled-background-color;
        cursor: not-allowed;
        opacity: 1;
        pointer-events: none;
    }

    nz-checkbox-wrapper {
        opacity: 0.65;
        cursor: not-allowed;
        pointer-events: none;
    }
    .ant-checkbox-wrapper {
        opacity: 0.65;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.ant-select-selection__rendered {
    display: flex;
    align-items: center;
}

.ant-form-horizontal .ant-form-item-control {
    flex: auto;
}

.ant-form-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &.bg-inverted {
        font-size: 1.25rem;
        background: @background-gray;
        padding: 0.75rem 2rem;
        margin: -0.75rem -1.5rem;
        i {
            &.loading-icon {
                margin: 0.75rem auto;
                font-size: 1.25rem;
            }
        }
    }
    .ant-form-item-label {
        text-align: left;
        line-height: 1.375rem;
        label {
            color: @text-secondary;
            font-family: 'Poppins', sans-serif;
            font-size: 0.75rem;
            height: 1.75rem;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}

.ant-form-item-label {
    padding-bottom: 0.375rem;
}

.ant-form-item-required::before {
    display: none !important;
}

.ant-form-item {
    margin-bottom: 1rem !important;
}

/**
    ANT DRAWER
**/

//nested in drawer custom elements
.history__table {
    .ant-table {
        border: none;
        margin-top: -1.5rem;
        margin-left: -1.25rem;
    }
}

.ant-drawer-header {
    border-radius: 0;
    border-top: 3px solid @blue-color;
    padding: 1.375rem 1rem;
    .ant-drawer-title {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        color: @text-primary;
    }
}
.ant-drawer-body {
    overflow: auto;
    height: calc(100vh - 155px);
    padding-bottom: 5rem;
    padding-top: 0;

    nz-form-control {
        width: 100%;
    }

    > * {
        display: block;
        margin-top: 1.5rem;
    }
}
.ant-drawer-close {
    display: none;
}

.drawer-actions-control {
    position: fixed;
    bottom: 0;
    margin: 0 -1.5rem;
    padding: 1.125rem 1.5rem;
    border-top: 1px solid @outline-gray;
    background: white;
    width: 100%;
    z-index: 99999;

    .drawer-controls {
        display: flex;

        button {
            margin-right: 0.6875rem;
        }
    }
}

/**
    ANT CHECKBOX
**/

.ant-checkbox-input {
    width: 1.25rem !important;
    height: 1.25rem !important;
}

.ant-checkbox-inner {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid @outline-gray;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border: 1px solid @outline-gray;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid @blue-color;
    border-top: 0;
    border-left: 0;
    width: 6.314286px;
    height: 13.64285714px;
    top: 42%;
    left: 22.7%;
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
    font-size: 1rem !important;
    color: @text-primary;
    letter-spacing: 0.01em;
    line-height: 1.375rem;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
    .ant-checkbox {
        margin-right: 0.25rem;
    }
}

.nested-checkboxes {
    margin-left: 2.25rem;
}

/**
    ANT SELECT
**/

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 2.75rem;
}

nz-date-picker {
    height: 2.5rem;
}

//date range picker

.ant-picker-range.ant-picker-large {
    border-color: @outline-gray;
    height: 2.75rem;
}

//multiple select
.ant-select-multiple.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    min-height: 2.75rem;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 0;
    height: 34px;
}

.ant-select-multiple .ant-select-selection-item {
    max-width: 10.25rem;
    z-index: 2;
    border: 1px solid @blue-color;
    background: rgba(48, 153, 204, 0.08);
    border-radius: 0.25rem;
    color: @blue-color;
    height: 34px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0.25rem 0.25rem 0.25rem 0;
}

.ant-select-selection-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    min-height: 2.5rem;
    background: #ffffff;
    border: 1px solid @outline-gray;
    box-sizing: border-box;
    border-radius: 0.1875rem;
    color: @text-primary;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.01em;
    &:hover,
    &:focus {
        border: 1px solid @text-primary;
        box-shadow: none;
    }
}

.ant-select-open {
    .anticon-down {
        transform: rotate(180deg);
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid #3d4663;
    box-shadow: none;
}

.ant-select-selection__clear {
    top: calc(100% - 23px) !important;
    margin-top: -3px;
}
.ant-select-arrow {
    font-size: 1.5rem;
    top: calc(100% - 22px) !important;
    right: 8px;
    color: @dark-gray;
}

.ant-select-dropdown-menu-item {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.01em;
    color: #e9300f;
}

.ant-select-selection__placeholder {
    display: block;
    z-index: 1;
    max-width: 250px;
    width: 250px;
    color: @text-tertiary;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.01em;
}

.ant-select-close-icon {
    color: @blue-color;
    margin-top: -0.25rem;
    margin-left: -0.375rem;
    font-size: 1.25rem;
}

.anticon-spin {
    color: @blue-color;
}
/**
    ANT TABS
**/
.ant-tabs-nav .ant-tabs-tab {
    padding: 0.375rem 1rem;
    margin: 0 0.875rem 0 0;
}

.ant-tabs-tab {
    a {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: @text-secondary;
    }
    &.ant-tabs-tab-active {
        a {
            color: @blue-color;
        }
    }
}

/**
    ANT MODALS
**/

.ant-modal {
    width: 30rem;
}

.ant-modal-body {
    margin-top: 0;
    margin-left: 0 !important;
    padding: 1.5rem 1rem;
    letter-spacing: 0.01em;
    font-size: 1rem;
    line-height: 1.375rem;
    color: @text-primary;
    border-bottom: 1px solid @outline-gray;
}

.ant-modal-header {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid @outline-gray;
    color: @text-primary;
}

.ant-modal-confirm .ant-modal-body {
    padding: 0;
}

.ant-modal-confirm-body > .anticon {
    display: none;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    padding: 1.5rem 1rem;
    border-bottom: 1px solid @outline-gray;
    color: @text-primary;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0;
    margin-left: 0 !important;
    padding: 1.5rem 1rem;
    letter-spacing: 0.01em;
    font-size: 1rem;
    line-height: 1.375rem;
    color: @text-primary;
    border-bottom: 1px solid @outline-gray;
}

.ant-modal-confirm-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5625rem;
}

.ant-modal-footer {
    padding: 1.25rem 1rem;
    margin-top: 0;
    border-top: none;
    display: flex;
    align-items: center;
    .action-btns {
        display: flex;
        align-items: center;
        button {
            margin-right: 0.75rem;
            i {
                font-size: 1.25rem;
            }
        }
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: left;
    display: flex;
    flex-direction: row-reverse;
    margin: 1rem 0.5rem;
}

.ant-modal-confirm .ant-modal-confirm-btns button {
    margin-right: 0.75rem;
    i {
        font-size: 1.25rem;
    }
}

.info-modal .ant-modal-confirm-title {
    display: none;
}

.info-modal .ant-modal-confirm-content {
    padding-bottom: 0;
}

.info-modal .ant-modal-confirm-btns {
    margin-left: 2.25rem;
}

.info-modal .info-mcd-logo {
    background: url('./assets/mcd-info-logo.png') no-repeat center center;
    width: 6rem;
    height: 6rem;
    margin: -1rem auto 0.5rem;
}

.info-modal {
    &.ant-modal {
        width: 32rem !important;
    }
    ol {
        li {
            padding-left: 0.75rem;
        }
    }
}

/**
    COLOR PICKER POPOVER
**/

.ant-popover.color-picker {
    .ant-popover-arrow {
        display: none;
    }
}

/**
    ANT RADIO BUTTON
**/
.ant-radio-inner,
ant-radio-input {
    width: 1.25rem;
    height: 1.25rem;
}

.ant-radio-checked .ant-radio-inner {
    border: 1px solid rgba(96, 106, 138, 0.5);
}

.ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.9);
}

.ant-radio-inner::after {
    top: 9px;
    left: 9px;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
}

/**
    DROPDOWN
**/

.menu-action-item {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: @text-primary;
    &.danger {
        color: @text-red;
    }
    &:hover {
        background-color: @outline-gray;
    }
}

/**
    ANT TOOLTIP
**/
.danger-tooltip {
    .ant-tooltip-arrow-content {
        background-color: @text-red;
    }
    .ant-tooltip-inner {
        background-color: @text-red;
        color: @white-color;
    }
}

.primary-tooltip {
    .ant-tooltip-arrow-content {
        background-color: @blue-color;
    }
    .ant-tooltip-inner {
        background-color: @blue-color;
        color: @white-color;
    }
}

/**
  ANT BUTTON
**/
button {
    &.ant-btn-default {
        i {
            color: @blue-color;
        }
    }
}

/**
  ANT ALERT
**/
nz-alert {
    .ant-alert-icon {
        top: 9.5px;
    }
    .ant-alert {
        border-radius: 1px;
        border: none;
        .ant-alert-message {
            font-family: 'Roboto', sans-serif;
            font-size: 0.75remm;
            line-height: 1.0625rem;
            display: flex;
            align-items: center;
        }
        &.ant-alert-warning {
            .ant-alert-message {
                color: #de9b02;
            }
        }
        &.ant-alert-info {
            background-color: @blue-color-light;
            .ant-alert-message {
                color: #3099cc;
            }
        }
    }
}

/**
* Headers
**/

h4,
h5 {
    font-family: 'Poppins', sans-serif;
}

/**
 ANT DATERANGE / CALENDAR
**/
.ant-calendar-picker-input {
    height: 2.75rem;
}
.ant-calendar-range {
    width: 622px;
}
.ant-calendar-picker-container {
    font-family: 'Roboto', sans-serif;
    .ant-calendar-header {
        > div {
            border-bottom: 1px solid @outline-gray;
        }
        .ant-calendar-my-select .ant-calendar-month-select,
        .ant-calendar-year-select {
            font-size: 1rem;
            line-height: 1.375rem;
            letter-spacing: 0.01em;
            color: @text-primary;
        }
    }
    .ant-calendar-body {
        padding-top: 0;
        font-family: 'Roboto', sans-serif;
        date-table {
            thead {
                tr {
                    border-bottom: 1px solid @outline-gray;
                }
                th.ant-calendar-column-header {
                    padding: 0.5rem 0.75rem;
                    color: @text-secondary;
                }
            }
            td {
                div.ant-calendar-date {
                    margin: 0.125rem 0.375rem;
                    margin-left: 0;
                }
                .ant-calendar-date {
                    height: 1.5rem;
                    width: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.125rem 0.375rem;
                    margin: 0;
                    width: 100%;
                    color: @text-primary;
                    font-size: 0.75rem;
                }
                &.ant-calendar-selected-day {
                    .ant-calendar-date {
                        color: @white-color;
                    }
                }
            }
        }
    }
}

/* ANT TRANSFER */
nz-transfer-list {
    .ant-checkbox-wrapper {
        margin-bottom: 0;
    }
}

.ant-transfer-list {
    border: 1px solid @outline-gray;
    border-radius: 0.125rem;
}

.ant-transfer-list-content-item {
    padding: 0.625rem 1rem;
    overflow: hidden;
    border-bottom: 1px solid @outline-gray;
}

.ant-transfer-operation .ant-btn .anticon {
    font-size: 1.25rem;
    line-height: 1;
    height: 1.25rem;
}

/**
  PULSE DOT
**/
.ring-container {
    position: relative;
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    width: 11px;
    height: 11px;
    background-color: @blue-color;
    border-radius: 50%;
    position: absolute;
}

.ringring {
    border: 2px solid @blue-color;
    border-radius: 22px;
    height: 22px;
    width: 22px;
    position: absolute;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}
@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.breadcrumb-back {
    padding: 0.25rem 0.75rem 0.25rem 0;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    color: @text-primary;
    border-radius: 0.125rem;
    transition: all 0.3s ease-in-out;
    i {
        margin-right: 0.5rem;
        font-size: 1.25rem;
        color: @blue-color;
    }
    &:hover {
        cursor: pointer;
        background-color: @outline-gray;
    }
}

nz-table-selection {
    .ant-checkbox-wrapper {
        margin-bottom: 0;
    }
}

.ant-table-selection-column {
    .ant-checkbox-wrapper {
        margin-bottom: 0;
    }
}

.ant-empty-description {
    color: @text-secondary;
}

nz-steps {
    .ant-steps {
        padding: 1.5rem 0 0.625rem;
    }

    .ant-steps-item-title {
        font-weight: 300;
        font-size: 0.875rem;
        color: @text-tertiary !important;
    }

    .ant-steps-item-wait
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        background: @text-tertiary;
    }

    .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        height: 0.125rem;
        background: rgba(147, 154, 176, 0.2);
    }

    .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        height: 0.125rem;
        background: rgba(147, 154, 176, 0.2);
    }

    .ant-steps-dot .ant-steps-item-tail::after {
        height: 0.125rem;
    }

    .ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        width: 0.75rem;
        height: 0.75rem;
        left: -0.125rem;
        top: -0.125rem;
    }
}

/* ANT POPOVER */
.ant-popover-arrow {
    display: none;
}

.ant-popover-inner {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.th-number {
    text-align: right !important;
}

.td-number {
    text-align: right;
}

nz-statistic .ant-statistic {
    margin-bottom: 1rem;

    .ant-statistic-title {
        color: @text-secondary;
    }

    .ant-statistic-content {
        color: @text-primary;
    }
}


// ANT UPLOAD

.ant-upload.ant-upload-drag {
    background: @background-gray;
    border: 1px dashed @outline-gray;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: @blue-color !important;
        .upload-content {
            display: none;
        }
        .upload-hover-content {
            display: flex;
        }
    }
    .upload-hover-content {
        display: none;
        padding: 4.525rem 0;
        flex-direction: column;
        align-items: center;
        color: @white-color;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        i {
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }
    }
    .upload-content {
        display: flex;
    }
}

.upload-content {
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
    .upload-content__text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        display: flex;
        align-items: flex-end;
        color: @text-primary;
        &.secondary {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 0.75rem;
            line-height: 1.0625rem;
            display: flex;
            align-items: flex-end;
            color: @text-secondary;
        }
    }
}

.uploads__file-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0.375rem 0 0;
    width: calc(100% + 1.25rem);
}

.uploads__drop-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem auto 0;
    .uploads__upload-btn {
        margin: 0.375rem auto;
    }
    nz-upload {
        width: 100%;
    }
}